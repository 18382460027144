.holder-data-container {
  margin-bottom: 1rem;
}

.birthdate-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1.5fr;
  gap: 1rem;
}

.case-date-selector {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.8rem;
}

.case-date-selector > button {
  max-width: 100%;
}

.p-overlaypanel {
  left: calc(100% - 40rem) !important;
  /* left: calc(100% - 60rem) !important; */
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .p-overlaypanel {
    left: calc(100% - 40rem) !important;
    /* left: calc(100% - 60rem) !important; */
  }
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .p-overlaypanel {
    left: calc(100% - 50rem) !important;
  }
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .p-overlaypanel {
    left: calc(100% - 18rem) !important;
  }
}
@media only screen and (device-width: 768px) {
  /* default iPad screens */
  .p-overlaypanel {
    left: calc(100% - 28rem) !important;
  }
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
}

/* media query */
/* @media (max-width: 768px) {
  .p-overlaypanel {
    left: calc(100% - 20rem) !important;
  }
}
@media (max-width: 1440px) {
  .p-overlaypanel {
    left: calc(100% - 40rem) !important;

  }
} */

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
}
