.advance-modal {
  min-width: 25vw;
}

.advance-modal-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: white;
}

.advance-modal-content form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.advance-modal-content form #saveInvoice {
  max-width: fit-content;
  place-self: end;
}

form#new-advance-form {
  min-width: 35vw;
  max-width: 35vw;
}

.rate-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.advance-summary-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: fit-content;
  gap: 1rem;
  margin-bottom: 1rem;
}

.advance-summary-container > .p-card {
  padding-inline: 1.5rem;
  /* text-align: center; */
}
