:root{
  --transition-duration: 0.2s;
  --menu-bg: #f9fafb;
}
.layout-dashboard {
  /* display: flex;
  flex-direction: column; */
  min-height: 100vh;
}

.layout-menubar {
  flex: 0 0 auto;
}

.layout-content {
  /* display: flex;
  flex: 1 1 auto; */
  margin-left: 18rem;
  transition: margin-left var(--transition-duration);
}
.layout-inactive{
  margin-left: 0;
}

.p-menu{
  width: auto;
}

.layout-sidebar {
  padding: 1rem;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 18rem;
  background: var(--menu-bg);
  border-right: var(--sidebar-border);
  box-shadow: var(--sidebar-shadow);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.layout-main {
  flex: 1 1 auto;
  padding: .5rem .5rem 1rem 0.5rem;
}

.layout-main-container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  justify-content: space-between;
  padding: 0.5rem 1rem 2rem 1rem;
}
.sidebar-hidden {
  display: none;
}

.back-link {
  color: #4b5563;
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 4px;
  padding-bottom: 0.7rem;
  max-width: fit-content;
}

.back-link:hover > span {
  text-decoration: underline;
}

.p-menuitem-content.active {
  background-color: #f3f4f6;
}


@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .layout-main-container {
    padding: 0 0 2rem 0;
  }
  .layout-main {
    padding: .2rem .2rem .2rem .2rem;
  }
}