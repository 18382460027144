.center-requirement-config {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.center-requirement-config > #btn-container,
.center-requirement-config > .btn-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-self: flex-end;
  max-width: fit-content;
}

.center-requirement-config > #btn-container > button,
.center-requirement-config > .btn-container > button {
  max-width: fit-content;
}

.day-row-selector {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
}

.day-row-selector > button {
  font-size: 0.8rem;
}
