.login-container > .p-card {
  max-width: max(25vw, 384px);
  margin-bottom: 100px;
}

.login-container {
  display: grid;
  place-items: center;
  min-height: 100dvh;
  background-color: #e3f5ff;
}
